<template>
  <div class="box">
    <van-cell-group inset title="电气连接图">
      <van-cell>
        <van-image
          width="100%"
          fit="contain"
          :src="require('../../assets/images/power1.png')"
          @click="onPreview(0)"
        />
      </van-cell>
    </van-cell-group>
    <!-- <van-cell-group inset title="基本信息">
      <van-cell title="箱编号" value="xbh2347823731002323" />
      <van-cell title="位置" value="江南大道与火炬大道路口" />
      <van-cell title="经度" value="104.888888" />
      <van-cell title="维度" value="29.888888" />
      <van-cell title="负责单位" value="杭州市滨江区交警支队" />
      <van-cell title="负责单位负责人" value="张三" />
      <van-cell title="联系电话" value="13333333333" />
      <van-cell title="承建单位" value="浙江舍为思智慧科技开发有限公司" />
      <van-cell title="承建单位负责人" value="张三" />
      <van-cell title="联系电话" value="13333333666" />
      <van-cell title="建设日期" value="2023-05-08" />
    </van-cell-group> -->
    <!-- 图片预览 -->
    <van-image-preview v-model="show" :images="images" :startPosition="startPosition" closeable />
  </div>
</template>

<script>
  export default {
    data() {
      return {
        show: false,
        images: [
          require('../../assets/images/power1.png')
        ],
        startPosition: 1
      }
    },
    methods: {
      onPreview(index) {
        this.startPosition = index
        this.show = true
      }
    }
  }
</script>

<style lang="less" scoped>
.box {
  padding: 16px 0 24px;
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
}
</style>